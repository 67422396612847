<template>
  <v-app-bar
      app
      color="primary"
      dark
      clipped-left
      clipped-right
  >
    <router-link to="/">
      <v-img
          alt="SunPeek Logo"
          :src="require('../assets/SunPeek_logo_dark.svg')"
          contain
          height="50"
          max-width="150"
      />
    </router-link>

    <v-spacer></v-spacer>

    <SunPeek_VersionInfo></SunPeek_VersionInfo>

    <v-divider vertical></v-divider>

    <v-btn href="mailto:support@sunpeek.org?subject=subject&body=Thank you for helping us to improve SunPeek. Please describe below how we can help you. We will get back to you as soon as possible. The content of your mail will be handled confidentially by the SunPeek maintainers." target="_blank" text small> <v-icon color="red">mdi-bug</v-icon></v-btn>
    <v-btn href="https://sunpeek.org/" target="_blank" text small> <v-icon color="light">mdi-web</v-icon></v-btn>
    <v-btn href="https://docs.sunpeek.org/" target="_blank" text small> <v-icon color="verylight">mdi-help-box-multiple-outline</v-icon></v-btn>
    <v-divider vertical></v-divider>

  </v-app-bar>
</template>

<script>


import SunPeek_VersionInfo from "@/components/SunPeek_VersionInfo.vue";

export default {
  name: 'SunPeekHeader',
  components: {SunPeek_VersionInfo},
}
</script>